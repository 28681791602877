import React from "react"
import SectionHeader from "../../components/UI/elements/SectionHeader"
import Layout from "../../components/UI/layout"

const Sectors = () => {
  return (
    <Layout>
      <SectionHeader headerText="General market and sector analysis" />
    </Layout>
  )
}

export default Sectors
